<template>
  <div>
    <S1 />
  </div>
</template>

<script>
export default {
  components: {
    S1: () => import("../components/contact/s1.vue"),
  },
  data() {
    return {};
  },
};
</script>

<style></style>
